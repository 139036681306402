body,
html {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

hr {
  max-width: 50px;
  border-width: 3px;
  border-color: #F05F40;
}

hr.light {
  border-color: #fff;
}

a {
  color: #F05F40;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  display: block;
}

a:hover {
  color: #f05f40;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

iframe {
  width: 100%;
}

.bg-primary {
  background-color: white !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.text-faded {
  color: rgba(255, 255, 255, 0.7);
}

section {
  padding: 4rem 0;
}

.section-heading {
  margin-top: 0;
}

.bg-shine {
  color: white;
  background-color: #3d3b5e !important;
  position: relative;
}

.content-justify-center {
  justify-content: center
}

.our-contact {
  text-align: center;
  padding-bottom: 20px;
}

::-moz-selection {
  color: #fff;
  background: #212529;
  text-shadow: none;
}

::selection {
  color: #fff;
  background: #212529;
  text-shadow: none;
}

img::-moz-selection {
  color: #fff;
  background: transparent;
}

img::selection {
  color: #fff;
  background: transparent;
}

img::-moz-selection {
  color: #fff;
  background: transparent;
}

#mainNav {
  border-bottom: 1px solid rgba(33, 37, 41, 0.1);
  background-color: #fff;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

#mainNav .navbar-brand {
  font-weight: 700;
  text-transform: uppercase;
  color: #F05F40;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

#mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
  color: #f05f40;
}

#mainNav .navbar-nav > li.nav-item > a.nav-link,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus {
  font-size: .9rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #212529;
}

#mainNav .navbar-nav > li.nav-item > a.nav-link:hover,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus:hover {
  color: #F05F40;
}

#mainNav .navbar-nav > li.nav-item > a.nav-link.active,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus.active {
  color: #F05F40 !important;
  background-color: transparent;
}

#mainNav .navbar-nav > li.nav-item > a.nav-link.active:hover,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus.active:hover {
  background-color: transparent;
}

header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 56px);
  background-position: center center;
  background-size: cover;
  position: relative;
}

header.masthead hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

header.masthead h1 {
  font-size: 2rem;
}

header.masthead p {
  font-weight: 300;
}

@media (min-width: 768px) {
  header.masthead p {
    font-size: 1.15rem;
  }
}

@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 650px;
    padding-top: 0;
    padding-bottom: 0;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  header.masthead h1 {
    font-size: 4rem;
  }
}

.service-box {
  max-width: 400px;
}

.portfolio-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto;
}

.portfolio-box .portfolio-box-caption {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  color: #fff;
  background: rgba(240, 95, 64, 0.9);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category,
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
  padding: 0 15px;
  font-display: auto;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
  font-size: 18px;
}

.portfolio-box:hover .portfolio-box-caption {
  opacity: 1;
}

.portfolio-box:focus {
  outline: none;
}

@media (min-width: 768px) {
  .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
    font-size: 16px;
  }
  .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    font-size: 22px;
  }
}

.text-primary {
  color: #F05F40 !important;
}

.btn {
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 300px;
  font-display: auto;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.btn-xl {
  padding: 1rem 2rem;
}

.btn-primary {
  background-color: #F05F40;
  border-color: #F05F40;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: #fff;
  background-color: #ee4b28 !important;
}

.btn-primary:active, .btn-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 95, 64, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgba(240, 95, 64, 0.5) !important;
}

.masthead-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.9) 0%, #000000 100%);
  opacity: 0.44;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.news h3 {
  font-size: 1.5rem;
  font-weight: 100;
}

.reviewPhoto {
  border-radius: 50%;
  width: 85px;
  height: 85px;
  margin-bottom: 2rem;
}

footer {
  background: black;
  padding: 20px 0;
  color: white;
  p {
    margin: 0;
  }
}

input[id^="spoiler"] {
  display: none;
}

input[id^="spoiler"] + label {
  display: block;
  margin: 0 auto;
  padding: 5px 20px;
  text-align: center;
  font-size: 24px;
  border-radius: 8px;
  cursor: pointer;
  transition: all .6s;
}

input[id^="spoiler"] ~ .spoiler {
  width: 90%;
  height: 0;
  overflow: hidden;
  opacity: 0;
  margin: 10px auto 0;
  padding: 10px;
}

input[id^="spoiler"]:checked + label + .spoiler {
  height: auto;
  opacity: 1;
  padding: 10px;
}

.contacts-address {
  max-width: 250px;
  margin: auto;
  padding-bottom: 20px;
}

.review-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 0;
}

.review-container {
  position: relative;
  z-index: 1;
}

.review-shape-fill {
  fill: rgba(0, 0, 0, 0.12);
}

.review-bg__svg {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
}

.mt-contacts a,
.mt-contacts a:hover {
  color: black;
  padding-bottom: 0.5rem;
}
